import * as React from 'react';
import { getLastClientCommandMessageId, saveLastClientCommandMessageId, applyDepcrecatedGoogleReviewsTemplate } from './store/common-impl';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import Layout from './components/Layout';
import Home from './components/Home';
import GoogleReviewTemplateCurrent from './components/GoogleReviewTemplateCurrent';
import GoogleReviewTemplateDeprecated from './components/GoogleReviewTemplateDeprecated';
import Message from './components/Message';
import Account from './components/Account';
import MessagesHistory from './components/MessagesHistory';
import './custom.css';
import { TRIBELY_SUPPORT_EMAIL, ERROR_ACCOUNT_NOT_CREATED } from './configs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ClientCommandTypes, ClientCommand } from './store/client-command.store';
import { Button } from 'reactstrap';
import { reloadPage } from './utils';
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { componentPaths } from "./configs";

const muiTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#3d5afe"
        },
        secondary: {
            main: "#009688"
        }
    }
});


const App = (props) => {

    const clientCommandShowPromptRefresh: boolean = shouldExecuteClientCommand(props.clientCommandEntity, ClientCommandTypes.PROMPT_REFRESH);
    const clientCommandShowInfo: boolean = shouldExecuteClientCommand(props.clientCommandEntity, ClientCommandTypes.SHOW_INFO);

    return (<React.Fragment>
        <Alert color="danger" isOpen={Boolean(props.error)}>
            {props.error} with your account. Contact support at {TRIBELY_SUPPORT_EMAIL} with reference code {ERROR_ACCOUNT_NOT_CREATED}.
        </Alert>
        <Alert color="warning" isOpen={clientCommandShowPromptRefresh}>
            <FontAwesomeIcon icon={faExclamationTriangle} size="lg" /> {clientCommandShowPromptRefresh && props.clientCommandEntity.params.message} <Button color="warning" onClick={() => dismissClientCommandMessageAndExecuteAction(props.clientCommandEntity.messageId, reloadPage)}>{clientCommandShowPromptRefresh && props.clientCommandEntity.params.promptActionText}</Button>
        </Alert>
        <Alert color="primary" isOpen={clientCommandShowInfo} toggle={() => dismissClientCommandMessageAndExecuteAction(props.clientCommandEntity.messageId)}>
            <FontAwesomeIcon icon={faInfoCircle} size="lg" /> {clientCommandShowInfo && props.clientCommandEntity.params.message} 
        </Alert>
        <ThemeProvider theme={muiTheme}>
            <Layout>
                <Switch>
                    <Route exact path={componentPaths["MessagesHistory"]} component={MessagesHistory} />
                    {applyDepcrecatedGoogleReviewsTemplate(props.accountEntity.dateCreated) === true ? <Route exact path={componentPaths["GoogleReviewTemplate"]} component={GoogleReviewTemplateDeprecated} />:<Route exact path={componentPaths["GoogleReviewTemplate"]} component={GoogleReviewTemplateCurrent} />}
                    <Route exact path={componentPaths["Message"]} component={Message} />
                    <Route exact path={componentPaths["Account"]} component={Account} />
                    <Route exact path={componentPaths["Home"]} component={Home} />
                </Switch>
            </Layout>
        </ThemeProvider>
    </React.Fragment>);
};

const shouldExecuteClientCommand = (clientCommandEntity: ClientCommand | undefined, clientCommandType: ClientCommandTypes): boolean => {
    if (!clientCommandEntity) {
        return false;
    }

    // Have we already executed this message? If yes, don't show it again.
    const existingMessageId = getLastClientCommandMessageId();
    if (existingMessageId !== null && existingMessageId === clientCommandEntity?.messageId) {
        return false;
    }

    // Finally, if the requested command matches the incoming command then execute
    return (clientCommandEntity.clientCommand === clientCommandType);
}

const dismissClientCommandMessageAndExecuteAction = (messageId: string, action: Function | undefined = undefined) => {
    saveLastClientCommandMessageId(messageId);
    if (action) {
        action();
    }
}

const mapStateToProps = (state) => {
    return {
        error: state.account.error,
        clientCommandEntity: state.clientCommand.clientCommandEntity,
        accountEntity: state.account.accountEntity
    }
};

export default connect(mapStateToProps, null)(App);
