// helper function to access the resource with the token
export const callApiWithAccessToken = (endpoint, token) => {
  const headers = new Headers();
  const bearer = `Bearer ${token}`;

  headers.append("Authorization", bearer);

  const options = {
      method: "GET",
      headers: headers
    };

  //fetch(endpoint, options)
  //  .then(response => response.json())
  //  .then(response => {
  //  }).catch(error => {
  //  });
}