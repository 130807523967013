import * as React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Formik } from 'formik';
import { RouteComponentProps } from 'react-router';
import * as Yup from 'yup';
import { ApplicationState } from '../store';
import { Form, FormGroup, FormFeedback, Alert, Spinner } from 'reactstrap';
import * as MessageStore from '../store/message.store';
import * as MessageSettingsStore from '../store/message-settings.store';
import * as auth from '../authProvider';
import { QUERY_API_KEY, BASE_API_URI, CUSTOMER_LABEL_SINGULAR, TRIAL_ACCOUNT_NUMBER } from '../configs';
import * as AccountStore from '../store/account.store';
import { capitalize } from '../utils';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { CUSTOMER_LABEL_PLURAL } from '../configs';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import "./Message.css";
import LoadingBackdrop from "./LoadingBackdrop";
import LearnMorePopover from "./LearnMorePopover";
import PageHeading from "./PageHeading";
import TrialUpgradeMessage from './TrialUpgradeMessage';
import QuickLinks from "./QuickLinks";
import { pageIcons, fillInsertedVariableValues, InsertableVariableValues } from '../configs';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const BACKSPACE_KEYCODE: number = 8;

window.Storage.prototype.setObject = function (key: string, value) {
    this.setItem(key, JSON.stringify(value));
}

window.Storage.prototype.getObject = function (key: string) {
    return JSON.parse(this.getItem(key) || '');
}

type MessageProps = MessageStore.MessageState &
    typeof MessageStore.actionCreators &
    MessageSettingsStore.MessageSettingsState &
    AccountStore.AccountState &
    typeof MessageSettingsStore.actionCreators &
    RouteComponentProps<{}>;

interface MessageComponentState {
    showSuccessAlert: boolean;
    showFailAlert: boolean;
    mobilePhoneSuggestions: Array<SelectRecord>;
    mobilePhoneHelpPopoverAnchor: any;
    messageHelpPopoverAnchor: any;
    messagePreviewPopoverAnchor: boolean;
    messagePreviewText: string;
}


interface SelectRecord {
    id: string | null;
    label: string;
    value: string;
    firstName: string;
    lastName: string;
}

class Message extends React.PureComponent<MessageProps, MessageComponentState> {

    state = {
        showSuccessAlert: false, showFailAlert: false, mobilePhoneSuggestions: [], mobilePhoneHelpPopoverAnchor: null, messageHelpPopoverAnchor: null, messagePreviewPopoverAnchor: false, messagePreviewText: ""
    }

    public static pageName: string = "Message";


    public showTimedSuccessAlert() {
        this.props.resetIsSentFlag();

        this.setState({ showSuccessAlert: true });
        setTimeout(() => this.setState({ showSuccessAlert: false }), 5000);
    }

    public componentDidMount() {
        this.ensureDataFetched();
    }

    public componentDidUpdate() {
        if (this.props.isSent) {
            this.showTimedSuccessAlert();
        }
    }

    private mobilePhoneFetchBlocked: boolean = true;

    // Tracks last key stroke. We're interested in backspace (8)
    private lastKeyStroke: number = 0;

    private suggestContactOptions = (mobilePhoneStart, overrideThrottle: boolean) => {
        // No need to fetch if not a number 
        if (!mobilePhoneStart || isNaN(mobilePhoneStart)) {
            return;
        }

        // Try to find it in the cached list first before fetching from the server, unless if the user is backspacing, 
        // then skip and go straight to the server to get matching items to the shirking mobilePhone,
        // otherwise, it will always match the start of what's in the cached list
        if (this.lastKeyStroke !== BACKSPACE_KEYCODE) {
            const existingIdx = (this.state.mobilePhoneSuggestions as unknown as Array<SelectRecord>)
                .findIndex((mobilePhone) => (mobilePhone.value.startsWith(mobilePhoneStart)));

            // Found it, return 
            if (existingIdx === 0)
                return;
        }

        // Go fetch from the server 
        this.fetchContactsWithThrottle(mobilePhoneStart, overrideThrottle);
    }

    private fetchContactsWithThrottle(mobilePhoneStart: string, overrideThrottle: boolean) {

        const throttleTime: number = 150; // milliseconds of blocking between calls

        // Block first to throttle this function execution
        // Before we hit the network and fetch on every key stroke, pace or slow down the fetching to be for a few keystrokes at a time instead of on every keystroke
        if (this.mobilePhoneFetchBlocked && !overrideThrottle) {
            setTimeout(() => { this.mobilePhoneFetchBlocked = false; }, throttleTime);
            return;
        }
        this.mobilePhoneFetchBlocked = true;

        // Fetch matching contacts that start with the entry from the server
        const url = `${BASE_API_URI}/contacts/${mobilePhoneStart}/?tenantId=${auth.getTenantId()}`;

        axios.get(url, { headers: { 'x-api-key': QUERY_API_KEY } })
            .then((result) => {
                this.setState({
                    mobilePhoneSuggestions: result.data.map((contact) => ({ id: `${contact.id}`, label: `${contact.mobilePhone} (${contact.firstName} ${contact.lastName})`, value: contact.mobilePhone, firstName: `${contact.firstName}`, lastName: `${contact.lastName}` }))
                });
            })
            .catch((error) => {
                this.props.setMessageContactsRequestFailed(error.message);
            })
    }

    public render() {

        const defaultValidationSchema = Yup.object().shape({
            mobilePhone: Yup.object().shape({
                label: Yup.string().required(),
                value: Yup.string()
                    .trim()
                    .length(10, 'Mobile phone number must be exactly 10 digits and all numbers')
                    .matches(/^[0-9]{10}$/, 'Mobile phone number must be exactly 10 digits and all numbers')
                    .required("Required")
                    .nullable()
            }),
            firstName: Yup.string()
                .min(2, "Too short")
                .max(25, "Too long")
                .required("Required"),
            lastName: Yup.string()
                .min(2, "Invalid name")
                .max(25, "Too long")
                .required("Required"),
            message: Yup.string()
                .min(2, "Too short")
                .max(512, "Too long")
                .required("Message cannot be empty")
        });

        const { title, subtitle } = {
            title: 'Request a Google Review',
            subtitle: `Text your ${CUSTOMER_LABEL_PLURAL} to leave a Google review`
        };

        const targetPlatformId = 'GMB';

        const setMobilePhoneField = (text) => {
            const phoneField = document.getElementById("mobilePhone");
            if (phoneField) {
                phoneField["value"] = text;
            }
        };

        const blockSending: boolean =
            this.props.accountEntity.remainingSends === undefined ? false : this.props.accountEntity.remainingSends <= 0;

        var isTemplateNotComplete: boolean = this.props.messageSettingsEntity.targetPlatforms &&
            (!this.props.messageSettingsEntity?.targetPlatforms[0].googlePlaceId ||
                this.props.messageSettingsEntity?.targetPlatforms[0].googlePlaceId.trim().length === 0);

        // See if it's complete according to legacy shortname
        if (isTemplateNotComplete) {
            isTemplateNotComplete = !(this.props.messageSettingsEntity?.targetPlatforms[0].shortName &&
                this.props.messageSettingsEntity?.targetPlatforms[0].shortName.trim().length !== 0);
        }

        const handleMessagePreviewOpen = (values: InsertableVariableValues) => {
            let previewText: string = fillInsertedVariableValues(this.props.messageSettingsEntity.targetPlatforms[0].messageBody, values) + ": " + this.props.messageSettingsEntity.targetPlatforms[0].uri + "\n\n" + fillInsertedVariableValues(this.props.messageSettingsEntity.targetPlatforms[0].messageFooter, values);
            this.setState({ messagePreviewPopoverAnchor: true, messagePreviewText: previewText });
        };

        const handleMessagePreviewClose = () => {
            this.setState({ messagePreviewPopoverAnchor: false });
        };

        const handleInsertableVariableFieldChange = (values: InsertableVariableValues, setField) => {
            setField("message", fillInsertedVariableValues(this.props.messageSettingsEntity.targetPlatforms[0].messageBody, values), false);
        }

        const sendButtonTitle = blockSending ? "Your remaining messages balance is insufficient" :
            isTemplateNotComplete ? "Complete the Google Review Template first." : "";

        return (
            <React.Fragment>
                <Alert color="warning" isOpen={this.props.accountEntity.accountNumber === TRIAL_ACCOUNT_NUMBER}>
                    <FontAwesomeIcon icon={faExclamationTriangle} size="lg" />
                    <div style={{ display: "inline-flex", alignItems: "center" }}>
                        <TrialUpgradeMessage remainingSends={this.props.accountEntity.remainingSends} />
                    </div>
                </Alert>
                <Alert color="warning" isOpen={isTemplateNotComplete}>
                    <FontAwesomeIcon icon={faExclamationTriangle} size="lg" /> Before sending a request, you need to complete filling out the Google Review template first. Go to Settings/Google Review Template or simply click <Link to="/google-review-template">here</Link> to complete.
                </Alert>
                <Alert color="success" isOpen={this.state.showSuccessAlert}>
                    Message has been sent
                </Alert>
                <Alert color="danger" isOpen={this.props.error !== null}>
                    {this.props.error}
                </Alert>
                <PageHeading title={title} subtitle={subtitle} noBottomPadding={true} titleIcon={pageIcons[Message.pageName]} />
                
                {this.props.messageSettingsEntity.targetPlatforms ? (
                    <Formik
                        initialValues={{
                            id: "",
                            type: "",
                            firstName: "",
                            lastName: "",
                            mobilePhone: "",
                            message: fillInsertedVariableValues(this.props.messageSettingsEntity.targetPlatforms[0].messageBody),
                            mobilePhoneSuggestions: []
                        }}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            setSubmitting(true);

                            const messageView: MessageStore.MessageView = {
                                id: values.id,
                                contactId: (values.mobilePhone as unknown as SelectRecord).id,
                                firstName: values.firstName,
                                lastName: values.lastName,
                                message: values.message,
                                mobilePhone: (values.mobilePhone as unknown as SelectRecord).value
                            };

                            this.props.sendMessage(messageView, targetPlatformId);



                            setSubmitting(false);
                            resetForm();
                        }}
                        validationSchema={defaultValidationSchema}
                    >
                        {props => {
                            const {
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                                setFieldTouched,
                                isValid,
                                validateForm
                            } = props;

                            return (
                                <Form onSubmit={handleSubmit}>
                                    <FormGroup>
                                        <LearnMorePopover>
                                            This is the mobile phone number of your {CUSTOMER_LABEL_SINGULAR} to send your review request to. Enter it as a 10-digit all-numbers including the area code (no hyphens, brackets or parentheses). Example: 5554444444
                                        </LearnMorePopover>
                                        <Autocomplete
                                            options={this.state.mobilePhoneSuggestions}

                                            id="mobilePhone"
                                            freeSolo={true}
                                            getOptionLabel={option => { return option["label"] !== undefined ? option["label"] : ""; }}
                                            renderInput={(params) => <TextField
                                                placeholder="10 digit mobile phone number"
                                                name="mobilePhone"
                                                value={values.mobilePhone}
                                                helperText={errors.mobilePhone !== undefined ? (errors.mobilePhone as unknown as SelectRecord).value : ""}
                                                error={errors.mobilePhone !== undefined} {...params} label={"Your " + capitalize(CUSTOMER_LABEL_SINGULAR) + "'s Mobile Phone"}
                                                onKeyDown={(event) => this.lastKeyStroke = event.keyCode}
                                                variant="outlined"

                                            />
                                            }
                                            onFocus={() => {
                                                if (values.mobilePhone !== "") {
                                                    setMobilePhoneField(values.mobilePhone["value"].split(" ")[0]);

                                                }
                                            }
                                            }
                                            blurOnSelect={true}
                                            clearOnEscape={true}
                                            onBlur={() => {
                                                setFieldTouched('mobilePhone', true, true);
                                            }}
                                            onInputChange={(event, newValue) => {
                                                setFieldValue('mobilePhone', { label: "l", value: newValue }, true);
                                                if (newValue.length === 10) {
                                                    this.suggestContactOptions(newValue, true);
                                                }
                                                else {
                                                    this.suggestContactOptions(newValue, false);
                                                }
                                            }}

                                            onChange={(event, newValue) => {
                                                if (newValue !== null) {
                                                    setFieldValue('mobilePhone', newValue, true);
                                                    setFieldValue('firstName', newValue["firstName"], false);
                                                    setFieldValue('lastName', newValue["lastName"], false);
                                                    handleInsertableVariableFieldChange(new InsertableVariableValues(newValue["firstName"], newValue["lastName"]), setFieldValue);
                                                    setTimeout(() => {
                                                        validateForm();
                                                    }, 100);
                                                }
                                            }}
                                        />

                                    </FormGroup>

                                    <FormGroup>
                                        <TextField
                                            variant="outlined"
                                            type="text"
                                            name="firstName"
                                            placeholder="Recipient's first name"
                                            id="firstName"
                                            label={"Your " + capitalize(CUSTOMER_LABEL_SINGULAR) + "'s First Name"}
                                            value={values.firstName}
                                            onChange={(event) => { handleChange(event); handleInsertableVariableFieldChange(new InsertableVariableValues(event.target.value, values.lastName), setFieldValue)}}
                                            onBlur={handleBlur}
                                            error={!!errors.firstName}
                                            helperText={errors.firstName}
                                            fullWidth={true}
                                        />
                                        <FormFeedback>{errors.firstName}</FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <TextField
                                            variant="outlined"
                                            type="text"
                                            name="lastName"
                                            placeholder="Recipient's last name"
                                            id="lastName"
                                            label={"Your " + capitalize(CUSTOMER_LABEL_SINGULAR) + "'s Last Name"}
                                            value={values.lastName}
                                            onChange={(event) => { handleChange(event); handleInsertableVariableFieldChange(new InsertableVariableValues(values.firstName, event.target.value), setFieldValue) }}
                                            onBlur={handleBlur}
                                            error={!!errors.lastName}
                                            helperText={errors.lastName}
                                            fullWidth={true}
                                        />
                                        <FormFeedback>{errors.lastName}</FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <LearnMorePopover>
                                            This message is coming from the template <Link to="/google-review-template">here</Link>.
                                        </LearnMorePopover>
                                        <TextField
                                            multiline={true}
                                            variant="filled"
                                            fullWidth={true}
                                            name="message"
                                            id="message"
                                            label="Message"
                                            placeholder="Message to recipient"
                                            value={values.message}
                                            onChange={handleChange}
                                            error={!!errors.message}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                        />
                                        <FormFeedback>{errors.message}</FormFeedback>
                                    </FormGroup>
                                    <Dialog open={this.state.messagePreviewPopoverAnchor} onClose={handleMessagePreviewClose}>
                                        <DialogTitle id="alert-dialog-title">{"Preview"}</DialogTitle>
                                        <DialogContent>
                                            <textarea readOnly style={{ zIndex: 99, position: "absolute", color: "white", marginLeft: "20%", marginTop: "5%", fontSize: "2.2vmin", border: "none", resize: "none", outline: "none", background: "transparent", width: "54%", height: "47%", pointerEvents: "all" }}>{this.state.messagePreviewText}</textarea>
                                            <img src="/IOS_Empty_Message_1.jpg" style={{width: "80%", height: "80%", marginLeft: "8%", pointerEvents: "none", userSelect: "none", WebkitUserSelect: "none", MozUserSelect: "none", msUserSelect: "none"}} />
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleMessagePreviewClose} style={{ color: "#3084d7" }} autoFocus>
                                                Close
                                            </Button>

                                        </DialogActions>
                                    </Dialog>
                                    <Button variant="contained" onClick={() => {handleMessagePreviewOpen(new InsertableVariableValues(values.firstName, values.lastName))}}>Preview Message</Button>
                                    <hr />
                                    <Button variant="contained" type="submit" onClick={() => setFieldValue('message', values.message)} color="primary" style={{ outline: "0", pointerEvents: "all", cursor: (!isValid || isSubmitting || isTemplateNotComplete || blockSending || Object.keys(touched).every((k) => touched[k] === false)) ? "not-allowed" : "pointer" }} disabled={!isValid || isSubmitting || isTemplateNotComplete || blockSending || Object.keys(touched).every((k) => touched[k] === false)} title={sendButtonTitle}>{this.props.isLoading && <Spinner animation="border" variant="success" size="sm" role="status" />} Send</Button>{' '}
                                    <Button variant="contained" style={{ outline: "0", marginRight: "20px" }} color="secondary" onClick={() => this.props.history.push('/')}>Close</Button>
                                    <QuickLinks pageNames={["MessagesHistory", "GoogleReviewTemplate"]} linkLabels={["See message history", "Configure Google template"]} />
                                </Form>
                            );
                        }}
                    </Formik>) :
                    <LoadingBackdrop open={true} />
                }
            </React.Fragment>
        );
    }

    private ensureDataFetched() {
        this.props.requestMessageSettings(this.props.messageSettingsEntity.dateUpdated);
    }
};

export default connect(
    // Since we're merging states here, order matters! Because they share common base properties, e.g., isLoading. The last one to the right trumps all to the left.
    (state: ApplicationState) => { return { ...state.messageSettings, ...state.account, ...state.message } },
    { ...MessageSettingsStore.actionCreators, ...MessageStore.actionCreators }
)(Message as any);