import * as React from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { RouteComponentProps } from 'react-router';
import * as Yup from 'yup';
import { ApplicationState } from '../store';
import { Form, FormGroup, Spinner, Alert } from 'reactstrap';
import * as MessageSettingsStore from '../store/message-settings.store';
import { CUSTOMER_LABEL_PLURAL, TRIBELY_SUPPORT_PHONE, TRIBELY_SUPPORT_EMAIL } from '../configs';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import LoadingBackdrop from "./LoadingBackdrop";
import LearnMorePopover from "./LearnMorePopover";
import PageHeading from "./PageHeading";
import QuickLinks from './QuickLinks';
import { insertIntoString, spliceOutOfString } from "../utils";
import { pageIcons, insertableTemplateVariables, defaultInsertableDelimiters } from '../configs';
import InsertableFieldChips from './InsertableFieldChips';
import Card from '@material-ui/core/Card';
import { CardContent } from '@material-ui/core';

type MessageSettingsProps =
    MessageSettingsStore.MessageSettingsState &
    typeof MessageSettingsStore.actionCreators &
    RouteComponentProps<{}>;

interface SettingsComponentState {
    showSuccessAlert: boolean;
    showFailAlert: boolean;
    focusedField: number;
    insertableFieldsDisabled: boolean;
}

enum FieldType {
    shortNameField,
    messageBodyField,
    messageFooterField
}

class GoogleReviewTemplateDeprecated extends React.PureComponent<MessageSettingsProps, SettingsComponentState> {

    state = {
        showSuccessAlert: false, showFailAlert: false, focusedField: -1, insertableFieldsDisabled: true
    }

    public static pageName: string = "GoogleReviewTemplate";

    public handleShowAlert(success: boolean) {
        const duration: number = 5000;

        if (success) {
            this.setState({ showSuccessAlert: true });
            setTimeout(() => this.setState({ showSuccessAlert: false }), duration);
        }
        else {
            this.setState({ showFailAlert: true });
            setTimeout(() => this.setState({ showFailAlert: false }), duration);
        }
    }

    handleFocusChange(field: FieldType) {
        this.setState({ focusedField: field });
    }

    public componentDidMount() {
        this.ensureDataFetched();
    }

    public componentDidUpdate(prevProps) {
        if (prevProps.messageSettingsEntity.dateUpdated &&
            this.props.messageSettingsEntity.dateUpdated !== prevProps.messageSettingsEntity.dateUpdated) {
            this.handleShowAlert(true);
        }
    }

    public render() {
        const defaultValidationSchema = Yup.object().shape({
            shortName: Yup.string().
                max(32, "Shortname too long").
                required("Your Google short name is required"),
            messageBody: Yup.string().
                max(512, "Message too long").
                min(2, "Message too short").
                required("Required"),
            messageFooter: Yup.string().
                max(20).
                min(2, "Footer too long").
                notRequired().
                nullable(),
            messageFooterLink: Yup.string().
                url().
                notRequired().
                nullable()
        });

        return (
            <React.Fragment>
                <Alert color="success" isOpen={this.state.showSuccessAlert}>
                    Message template has been saved successfully
                </Alert>
                <Alert color="danger" isOpen={this.props.error !== null}>
                    {this.props.error}
                </Alert>
                <PageHeading title="Message Template" subtitle="Google My Business (GMB)" titleIcon={pageIcons[GoogleReviewTemplateDeprecated.pageName]} />
                {this.props.messageSettingsEntity.targetPlatforms ? (
                    <Formik
                        initialValues={{
                            id: this.props.messageSettingsEntity.targetPlatforms[0].id,
                            uri: this.props.messageSettingsEntity.targetPlatforms[0].uri,
                            shortName: this.props.messageSettingsEntity.targetPlatforms[0].shortName,
                            googleBusinessName: this.props.messageSettingsEntity.targetPlatforms[0].googleBusinessName,
                            googlePlaceId: this.props.messageSettingsEntity.targetPlatforms[0].googlePlaceId,
                            googlePlaceIdAddress: this.props.messageSettingsEntity.targetPlatforms[0].googlePlaceIdAddress,
                            messageBody: this.props.messageSettingsEntity.targetPlatforms[0].messageBody,
                            messageFooter: this.props.messageSettingsEntity.targetPlatforms[0].messageFooter,
                            messageFooterLink: this.props.messageSettingsEntity.targetPlatforms[0].messageFooterLink
                        }}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            setSubmitting(true);
                            this.props.submitMessageSettings(values);
                            this.setState({ showSuccessAlert: true });
                            setSubmitting(false);
                            resetForm();
                        }}
                        validationSchema={defaultValidationSchema}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isValid,
                            handleReset,
                            isSubmitting,
                            setFieldValue
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <FormGroup>
                                    <LearnMorePopover>
                                        <p>A Google My Business (GMB) short name is a name that you can claim and reserve from Google to uniquely identify your business.</p><p>You need a GMB short name to direct your {CUSTOMER_LABEL_PLURAL} straight to your Google's reviews page. Click <a href='https://support.google.com/business/answer/9273900' target='_blank'>here</a> to learn more and create your GMB short name.</p> <p>If you need help doing this feel free to call us at <strong>{TRIBELY_SUPPORT_PHONE}</strong> or email us at <strong>{TRIBELY_SUPPORT_EMAIL}</strong></p>.
                                        </LearnMorePopover>
                                    <TextField
                                        variant="outlined"
                                        type="text"
                                        name="shortName"
                                        placeholder="Your GMB Short Name"
                                        id="shortName"
                                        label="Your Google My Business Short Name"
                                        value={values.shortName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!!errors.shortName}
                                        helperText={errors.shortName}
                                        onFocus={() => { this.handleFocusChange(FieldType.shortNameField) }}
                                        fullWidth={true}
                                    />

                                </FormGroup>
                                <Card variant="outlined">
                                    <CardContent>
                                        <LearnMorePopover>
                                            This is the message nudging your {CUSTOMER_LABEL_PLURAL} to rate and enter a review for your business. Do not put any links here.
                                        </LearnMorePopover>
                                        <Typography gutterBottom variant="h6" component="h2">
                                            Message Body
                                        </Typography>
                                        <FormGroup>
                                            <InsertableFieldChips delimiters={defaultInsertableDelimiters} disabled={this.state.focusedField != FieldType.messageBodyField} inputId={"messageBody"} insertableFieldNames={["firstName", "lastName"]} labels={["First Name", "Last Name"]} setInput={(fieldName, value) => { setFieldValue(fieldName, value, true) }} />
                                            <TextField
                                                variant="outlined"
                                                type="text"
                                                name="messageBody"
                                                placeholder="Message asking for a review"
                                                id="messageBody"
                                                label="Message Body"
                                                value={values.messageBody}
                                                onChange={handleChange}
                                                onBlur={(e) => { handleBlur(e); this.state.focusedField = -1; }}
                                                error={!!errors.messageBody}
                                                helperText={errors.messageBody}
                                                onFocus={() => { this.handleFocusChange(FieldType.messageBodyField) }}
                                                style={{marginTop: "1.5rem"}}
                                                fullWidth={true}
                                            />
                                        </FormGroup>
                                    </CardContent>
                                </Card>
                                <Card variant="outlined" style={{ marginTop: "1rem" }}>
                                    <CardContent>
                                        <LearnMorePopover>
                                            The message footer is usually a short thank you or something to that effect. This is optional.
                                        </LearnMorePopover>
                                        <Typography gutterBottom variant="h6" component="h2">
                                            Message Footer
                                        </Typography>
                                        <FormGroup>
                                            <InsertableFieldChips delimiters={defaultInsertableDelimiters} disabled={this.state.focusedField != FieldType.messageFooterField} inputId={"messageFooter"} insertableFieldNames={["firstName", "lastName"]} labels={["First Name", "Last Name"]} setInput={(fieldName, value) => { setFieldValue(fieldName, value, true) }} />
                                            <TextField
                                                variant="outlined"
                                                type="text"
                                                name="messageFooter"
                                                placeholder="Optional footer text"
                                                id="messageFooter"
                                                label="Message Footer"
                                                value={values.messageFooter}
                                                onChange={handleChange}
                                                onBlur={(e) => { handleBlur(e); this.state.focusedField = -1; }}
                                                error={!!errors.messageFooter}
                                                helperText={errors.messageFooter}
                                                onFocus={() => { this.handleFocusChange(FieldType.messageFooterField) }}
                                                style={{ marginTop: "1.5rem" }}
                                                fullWidth={true}
                                            />
                                        </FormGroup>
                                    </CardContent>
                                </Card>
                                <hr />
                                <Button variant="contained" type="submit" color="primary" style={{ outline: "0", pointerEvents: "all", cursor: (!isValid || isSubmitting || Object.keys(touched).every((k) => touched[k] === false)) ? "not-allowed" : "pointer" }} disabled={!isValid || isSubmitting || Object.keys(touched).every((k) => touched[k] === false)}>{this.props.isLoading && <Spinner animation="border" variant="success" size="sm" role="status" />} Save</Button>{' '}
                                <Button variant="contained" style={{ outline: "0", marginRight: "20px" }} color="secondary" onClick={() => this.props.history.push('/')}>Close</Button>
                                <QuickLinks pageNames={["Message", "Account"]} linkLabels={["Request a review", "View account detials"]} />
                            </Form>

                        )

                        }
                    </Formik>) :
                    <LoadingBackdrop open={true} />
                }
            </React.Fragment>
        );
    }

    private ensureDataFetched() {
        this.props.requestMessageSettings(this.props.messageSettingsEntity.dateUpdated);
    }
};

export default connect(
    (state: ApplicationState) => state.messageSettings,
    MessageSettingsStore.actionCreators
)(GoogleReviewTemplateDeprecated as any);
