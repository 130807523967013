import * as React from 'react';
import * as Configs from '../configs';

const Footer = () => (
    <React.Fragment>
        <br />
        <br />
        <br />
        <footer>
            &copy; 2020 - { new Date().getFullYear() } <a href="https://www.webnova.ca" target="_blank"> WebNova, Inc</a>. All rights reserved. <span style={{ float: 'right' }}>{Configs.APP_VERSION}</span>
        </footer>
    </React.Fragment>
);

export default Footer;