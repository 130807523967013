import * as React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import HistoryIcon from '@material-ui/icons/History';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import { RouteComponentProps } from 'react-router';
import { Link, Link as RouterLink } from 'react-router-dom';
import * as auth from '../authProvider';
import { CUSTOMER_LABEL_PLURAL } from '../configs';
import * as AccountStore from '../store/account.store';
import * as MessageSettingsStore from '../store/message-settings.store';
import PageHeading from "./PageHeading";
import { ApplicationState } from '../store';
import { componentPaths } from "../configs";
import { Redirect } from 'react-router-dom';
import { isEmpty } from '../utils';
import { applyDepcrecatedGoogleReviewsTemplate } from '../store/common-impl';

export const path: string = "/";

type MessageSettingsProps =
    MessageSettingsStore.MessageSettingsState &
    AccountStore.AccountState &
    typeof MessageSettingsStore.actionCreators &
    RouteComponentProps<{}>;

class Home extends React.PureComponent<MessageSettingsProps> {

    public render() {

        // Were we redirected by the nav menu to logout? (We can only sign out from home)
        if (this.props && this.props.location?.state) {
            const { logout } = this.props.location.state;

            if (logout) {
                auth.signOut();
            }
        }

        this.props.requestMessageSettings(this.props.messageSettingsEntity.dateUpdated);

        return (
            <React.Fragment>
                {
                    this.props.messageSettingsEntity.targetPlatforms &&
                        this.props.accountEntity.dateCreated &&
                        applyDepcrecatedGoogleReviewsTemplate(this.props.accountEntity.dateCreated) === false &&
                        isEmpty(this.props.messageSettingsEntity.targetPlatforms[0].googlePlaceId) ?
                            <Redirect to={componentPaths["GoogleReviewTemplate"]} /> :
                            <div>
                                <PageHeading title="Dashboard" subtitle="Boost your online ratings" />
                                <Grid container
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                    spacing={3}
                                >
                                    <Grid item xs={12} md={6}>
                                        <Card style={{ minHeight: '190px', position: 'relative' }}>
                                            <CardContent>
                                                <div style={{ display: "inline-flex" }}>
                                                    <StarHalfIcon style={{ paddingRight: "5px", paddingTop: "5px", transform: "scale(1.3)" }} />
                                                    <Typography gutterBottom variant="h5" component="h2">
                                                        Request a Google Review
                                                    </Typography>
                                                </div>
                                                <Typography style={{ paddingBottom: "3%" }} variant="body2" color="textSecondary" component="p">
                                                    Ask your {CUSTOMER_LABEL_PLURAL} to share their feedback by directing them to a reivew site such as <i>Google My Business Reviews</i>. You must first create a message <Link to="/google-review-template">template</Link>
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                                <Button style={{ color: "white !important" }} variant="contained" component={RouterLink} to="/message" color="primary" size="large">Request a Google Review</Button>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Card style={{ minHeight: '190px', position: 'relative' }}>
                                            <CardContent>
                                                <div style={{ display: "inline-flex" }}>
                                                    <HistoryIcon style={{ paddingRight: "5px", paddingTop: "5px", transform: "scale(1.3)" }} />
                                                    <Typography gutterBottom variant="h5" component="h2">
                                                        Recent Requests
                                                    </Typography>
                                                </div>
                                                <Typography style={{ paddingBottom: "3%" }} variant="body2" color="textSecondary" component="p">
                                                    View the most recent requests that have been sent out to your {CUSTOMER_LABEL_PLURAL}.
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                                <Button style={{ color: "white !important", marginTop: "39px" }} variant="contained" component={RouterLink} to="/messages-history" color="secondary" size="large">Show Messages History</Button>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                </Grid>
                                <br />
                                <br />
                            </div>}
                    </React.Fragment>);
    }
}

export default connect(
    (state: ApplicationState) => {
        return { ...state.messageSettings, ...state.account }
    },
    MessageSettingsStore.actionCreators
)(Home as any);
