import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavbarText } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import './NavMenu.css';
import * as auth from '../authProvider';
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from "@material-ui/icons/Settings";
import LogOutIcon from "@material-ui/icons/ExitToApp";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { TRIBLEY_CONTACT_URL } from '../configs';
import { Divider } from '@material-ui/core';

class NavMenu extends React.PureComponent<{}, { isOpen: boolean, logout: boolean, isSettingsMenuOpen: boolean, anchorEl }> {
    public state = {
        isOpen: false,
        logout: false,
        isSettingsMenuOpen: false,
        anchorEl: null
    };

    handleMenu = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    }

    handleMenuClose = () => {
        this.setState({ anchorEl: null})
    }

    handleLogout = () => {
        this.setState({ logout: true });
    }

    public render() {
        if (this.state.logout) {
            return (<Redirect
                to={{
                    pathname: "/",
                    state: { logout: true }
                }} />);
        }

        const userDisplayName = auth.getDisplayName();

        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/" title="Home"><img src="/logo.png?v=3" /></NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={this.state.isOpen} navbar>
                            <ul className="navbar-nav flex-grow">
                                <IconButton onClick={this.handleMenu}
                                    aria-label="Settings"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    color="inherit"
                                    className="navMenuIconButton"
                                >                                        
                                    <SettingsIcon />
                                </IconButton>
                                <Menu
                                    anchorEl={this.state.anchorEl}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(this.state.anchorEl)}
                                    onClose={this.handleMenuClose}
                                >
                                    <MenuItem component={Link} to="/google-review-template" onClick={this.handleMenuClose}>Google Review Template</MenuItem>
                                    <MenuItem component={Link} to="/account" onClick={this.handleMenuClose}>My Account</MenuItem>
                                    <Divider />
                                    <MenuItem component="a" href={TRIBLEY_CONTACT_URL} target="_blank" onClick={this.handleMenuClose}>Feedback</MenuItem>
                                </Menu>
                                <IconButton
                                    onClick={this.handleLogout}
                                    aria-label="Log Out"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    color="inherit"
                                    className="navMenuIconButton"
                                >
                                    <LogOutIcon />
                                </IconButton>
                            </ul>
                            <NavbarText>{userDisplayName}</NavbarText>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }

    private toggleNavbar = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}
{/*<UncontrolledDropdown nav inNavbar>
    <DropdownToggle nav title="settings">
        <FontAwesomeIcon icon={faCog} size="lg" />
    </DropdownToggle>
    <DropdownMenu right>
        <DropdownItem header>
            Settings
                                        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem>
            <NavLink tag={Link} className="text-dark" to="/google-review-template">
                Google Review Template
                                            </NavLink>
        </DropdownItem>
        <DropdownItem>
            <NavLink tag={Link} className="text-dark" to="/account">
                Account
                                            </NavLink>
        </DropdownItem>
    </DropdownMenu>
</UncontrolledDropdown>
    <NavItem title="Sign out">
        <NavLink className="text-dark clickableDefault" onClick={() => this.handleLogout()}>
            <FontAwesomeIcon icon={faSignOutAlt} size="lg" />
        </NavLink>
    </NavItem>*/}

export default NavMenu;
