import * as React from 'react';
import { CardContent } from '@material-ui/core';
import Button from "@material-ui/core/Button";
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Clear from '@material-ui/icons/Clear';
import axios from 'axios';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { Alert, Form, FormGroup, Spinner } from 'reactstrap';
import * as Yup from 'yup';
import { CUSTOMER_LABEL_PLURAL, defaultInsertableDelimiters, GOOGLE_PLACEID_API_URI, pageIcons, TRIBELY_SUPPORT_EMAIL, TRIBELY_SUPPORT_PHONE } from '../configs';
import { ApplicationState } from '../store';
import * as AccountStore from '../store/account.store';
import * as MessageSettingsStore from '../store/message-settings.store';
import InsertableFieldChips from './InsertableFieldChips';
import LearnMorePopover from "./LearnMorePopover";
import LoadingBackdrop from "./LoadingBackdrop";
import PageHeading from "./PageHeading";
import QuickLinks from './QuickLinks';
import { RouteComponentProps } from 'react-router';
import { isEmpty } from '../utils';

type MessageSettingsProps =
    MessageSettingsStore.MessageSettingsState &
    AccountStore.AccountState &
    typeof MessageSettingsStore.actionCreators &
    RouteComponentProps<{}>;

interface SettingsComponentState {
    showSuccessAlert: boolean;
    showFailAlert: boolean;
    focusedField: number;
    insertableFieldsDisabled: boolean;
    showPlaceIdConfirm: boolean;
    googleBusinessName: string;
    googlePlaceId: string;
    googlePlaceIdAddress: string

}

enum FieldType {
    googleBusinessNameField,
    messageBodyField,
    messageFooterField
}

interface GooglePlaceIdCandidateResponse {
    formatted_address: string,
    name: string,
    place_id: string
}

interface GooglePlaceIdResponse {
    candidates: GooglePlaceIdCandidateResponse[],
    status: string
}


class GoogleReviewTemplateCurrent extends React.PureComponent<MessageSettingsProps, SettingsComponentState> {

    state = {
        showSuccessAlert: false,
        showFailAlert: false,
        focusedField: -1,
        insertableFieldsDisabled: true,
        showPlaceIdConfirm: false,
        googleBusinessName: "",
        googlePlaceId: "",
        googlePlaceIdAddress: ""
    }
    
    private fetchGooglePlaceId() {

        const googlePlaceIdQuery = `${this.props.accountEntity.userDisplayName} ${this.props.accountEntity.userCity}`;
        const googlePlaceIdApiCall = encodeURI(`${GOOGLE_PLACEID_API_URI}/${googlePlaceIdQuery}`);

        axios.get(googlePlaceIdApiCall)
            .then((response) => {
                var candidates = response['data']['candidates'] as unknown as GooglePlaceIdResponse; 

                if (response && candidates) {
                    this.setState({
                        googleBusinessName: candidates[0].name,
                        googlePlaceId: candidates[0].place_id,
                        googlePlaceIdAddress: candidates[0].formatted_address,
                        showPlaceIdConfirm: true
                    });
                }
                else {
                    throw new Error('Failed to fetch place id');
                }
            })
            .catch((error) => {
                alert(error.message);
                console.log(error);
            })
    }

    public static pageName: string = "GoogleReviewTemplate";

    public handleShowAlert(success: boolean) {
        const duration: number = 5000;

        if (success) {
            this.setState({ showSuccessAlert: true });
            setTimeout(() => this.setState({ showSuccessAlert: false }), duration);
        }
        else {
            this.setState({ showFailAlert: true });
            setTimeout(() => this.setState({ showFailAlert: false }), duration);
        }
    }

    handleFocusChange(field: FieldType) {
        this.setState({ focusedField: field });
    }

    public componentDidMount() {
        this.ensureDataFetched();
    }

    public componentDidUpdate(prevProps) {
        if (prevProps.messageSettingsEntity.dateUpdated &&
            this.props.messageSettingsEntity.dateUpdated !== prevProps.messageSettingsEntity.dateUpdated) {
            this.handleShowAlert(true);
        }
    }

    private isMissingGoogleSetup() {
        return isEmpty(this.props.messageSettingsEntity.targetPlatforms[0].googlePlaceId);
    }

    public render() {
        const defaultValidationSchema = Yup.object().shape({
            googleBusinessName: Yup.string().
                max(80, "Google business name too long").
                required("Your Google business name is required"),
            messageBody: Yup.string().
                max(512, "Message too long").
                min(2, "Message too short").
                required("Required"),
            messageFooter: Yup.string().
                max(20).
                min(2, "Footer too long").
                notRequired().
                nullable(),
            messageFooterLink: Yup.string().
                url().
                notRequired().
                nullable()
        });
        const findYourBusinessOnGoogleLabel = "Find Your Business on Google";

        const handlePlaceIdConfirmClose = (setFieldValue, values) => {
            setGoogleSetupValue(this.state.googlePlaceId, this.state.googleBusinessName, this.state.googlePlaceIdAddress, setFieldValue, values);
            this.setState({ showPlaceIdConfirm: false });
            this.props.submitMessageSettings(values);
        };

        const handlePlaceIdReport = () => {
            alert(`To help you get your correct business from Google, please contact Tribely at ${TRIBELY_SUPPORT_PHONE} or send an email to ${TRIBELY_SUPPORT_EMAIL}.`);

            this.setState({ showPlaceIdConfirm: false });
        };

        const handlePlaceIdCancel = () => {
            this.setState({ showPlaceIdConfirm: false });
        };

        const handleGetGoogleBusinessName = () => {
            this.fetchGooglePlaceId();
        };

        const handleRemoveGoogleBusinessName = (setFieldValue, values) => {
            setGoogleSetupValue('', '', '', setFieldValue, values);

            this.props.submitMessageSettings(values);
        };

        const setGoogleSetupValue = (googlePlaceId, googleBusinessName, googlePlaceIdAddress, setFieldValue, values) => {
            setFieldValue('googleBusinessName', googleBusinessName, true);
            setFieldValue('googlePlaceId', googlePlaceId, true);
            setFieldValue('googlePlaceIdAddress', googlePlaceIdAddress, true);
            this.props.messageSettingsEntity.targetPlatforms[0].googlePlaceId = googlePlaceId;
            this.props.messageSettingsEntity.targetPlatforms[0].googlePlaceIdAddress = googlePlaceIdAddress;
            this.props.messageSettingsEntity.targetPlatforms[0].googleBusinessName = googleBusinessName;
            values.googleBusinessName = googleBusinessName;
            values.googlePlaceIdAddress = googlePlaceIdAddress;
            values.googlePlaceId = googlePlaceId;
        }

        return (
            <React.Fragment>
                <Alert color="success" isOpen={this.state.showSuccessAlert}>
                    Message template has been saved successfully
                </Alert>
                <Alert color="danger" isOpen={this.props.error !== null}>
                    {this.props.error}
                </Alert>
                <PageHeading title="Message Template" subtitle="Google Reviews" titleIcon={pageIcons[GoogleReviewTemplateCurrent.pageName]} />
                {this.props.messageSettingsEntity.targetPlatforms ? (
                    <Formik
                        enableReinitialize={true}
                        validateOnChange={true}
                        validateOnBlur={true}
                        initialValues={{
                            id: this.props.messageSettingsEntity.targetPlatforms[0].id,
                            uri: this.props.messageSettingsEntity.targetPlatforms[0].uri,
                            shortName: this.props.messageSettingsEntity.targetPlatforms[0].shortName,
                            googleBusinessName: this.props.messageSettingsEntity.targetPlatforms[0].googleBusinessName,
                            googlePlaceId: this.props.messageSettingsEntity.targetPlatforms[0].googlePlaceId,
                            googlePlaceIdAddress: this.props.messageSettingsEntity.targetPlatforms[0].googlePlaceIdAddress,
                            messageBody: this.props.messageSettingsEntity.targetPlatforms[0].messageBody,
                            messageFooter: this.props.messageSettingsEntity.targetPlatforms[0].messageFooter,
                            messageFooterLink: this.props.messageSettingsEntity.targetPlatforms[0].messageFooterLink
                        }}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            setSubmitting(true);
                            this.props.submitMessageSettings(values);
                            this.setState({ showSuccessAlert: true });
                            setSubmitting(false);
                            resetForm();
                        }}
                        validationSchema={defaultValidationSchema}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isValid,
                            handleReset,
                            isSubmitting,
                            setFieldValue,
                            setTouched,
                            validateForm
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <FormGroup>
                                    <LearnMorePopover>
                                        <p>This is your business name that receives the reviews under Google Reviews. If this is not accurate, you need to contact <strong>{TRIBELY_SUPPORT_PHONE}</strong> or email us at <strong>{TRIBELY_SUPPORT_EMAIL} to fix this.</strong></p>.
                                    </LearnMorePopover>
                                    {this.isMissingGoogleSetup() ?
                                        <Button onClick={handleGetGoogleBusinessName} size="medium" variant="contained" color="primary">
                                            {findYourBusinessOnGoogleLabel}
                                        </Button> :
                                        <Chip
                                            icon={<Clear />}
                                            variant="outlined"
                                            id="googleBusinessName"
                                            size="medium"
                                            label={values.googleBusinessName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            onFocus={() => { this.handleFocusChange(FieldType.googleBusinessNameField) }}
                                            onClick={() => handleRemoveGoogleBusinessName(setFieldValue, values)}
                                        />
                                    }
                                </FormGroup>
                                <Dialog open={this.state.showPlaceIdConfirm} onClose={handlePlaceIdConfirmClose}>
                                    <DialogTitle id="alert-dialog-title">Is this your business?</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            <Typography variant="subtitle1">{this.state.googleBusinessName}</Typography>
                                            <Typography variant="subtitle1">{this.state.googlePlaceIdAddress}</Typography>
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => handlePlaceIdCancel()} style={{ color: "#3084d7" }}>
                                            Cancel
                                        </Button>
                                        <Button onClick={() => handlePlaceIdReport()} style={{ color: "#3084d7" }}>
                                            Report Wrong Business
                                        </Button>
                                        <Button onClick={() => handlePlaceIdConfirmClose(setFieldValue, values)} color="primary" variant="contained" autoFocus>
                                            Confirm
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <Card variant="outlined">
                                    <CardContent>
                                        <LearnMorePopover>
                                            This is the message nudging your {CUSTOMER_LABEL_PLURAL} to rate and enter a review for your business. Do not put any links here.
                                        </LearnMorePopover>
                                        <Typography gutterBottom variant="h6" component="h2">
                                            Message Body
                                        </Typography>
                                        <FormGroup>
                                            <InsertableFieldChips delimiters={defaultInsertableDelimiters} disabled={this.state.focusedField != FieldType.messageBodyField} inputId={"messageBody"} insertableFieldNames={["firstName", "lastName"]} labels={["First Name", "Last Name"]} setInput={(fieldName, value) => { setFieldValue(fieldName, value, true) }} />
                                            <TextField
                                                variant="outlined"
                                                type="text"
                                                name="messageBody"
                                                placeholder="Message asking for a review"
                                                id="messageBody"
                                                label="Message Body"
                                                value={values.messageBody}
                                                onChange={handleChange}
                                                onBlur={(e) => { handleBlur(e); this.state.focusedField = -1; }}
                                                error={!!errors.messageBody}
                                                helperText={errors.messageBody}
                                                onFocus={() => { this.handleFocusChange(FieldType.messageBodyField) }}
                                                style={{marginTop: "1.5rem"}}
                                                fullWidth={true}
                                            />
                                        </FormGroup>
                                    </CardContent>
                                </Card>
                                <Card variant="outlined" style={{ marginTop: "1rem" }}>
                                    <CardContent>
                                        <LearnMorePopover>
                                            The message footer is usually a short thank you or something to that effect. This is optional.
                                        </LearnMorePopover>
                                        <Typography gutterBottom variant="h6" component="h2">
                                            Message Footer
                                        </Typography>
                                        <FormGroup>
                                            <InsertableFieldChips delimiters={defaultInsertableDelimiters} disabled={this.state.focusedField != FieldType.messageFooterField} inputId={"messageFooter"} insertableFieldNames={["firstName", "lastName"]} labels={["First Name", "Last Name"]} setInput={(fieldName, value) => { setFieldValue(fieldName, value, true) }} />
                                            <TextField
                                                variant="outlined"
                                                type="text"
                                                name="messageFooter"
                                                placeholder="Optional footer text"
                                                id="messageFooter"
                                                label="Message Footer"
                                                value={values.messageFooter}
                                                onChange={handleChange}
                                                onBlur={(e) => {
                                                    handleBlur(e); this.state.focusedField = -1; }}
                                                error={!!errors.messageFooter}
                                                helperText={errors.messageFooter}
                                                onFocus={() => { this.handleFocusChange(FieldType.messageFooterField); }}
                                                style={{ marginTop: "1.5rem" }}
                                                fullWidth={true}
                                            />
                                        </FormGroup>
                                    </CardContent>
                                </Card>
                                <hr />
                                <Button variant="contained" type="submit" color="primary" style={{ outline: "0", pointerEvents: "all", cursor: (!isValid || isSubmitting || Object.keys(touched).every((k) => touched[k] === false)) ? "not-allowed" : "pointer" }} disabled={!isValid || isSubmitting || Object.keys(touched).every((k) => touched[k] === false)}>{this.props.isLoading && <Spinner animation="border" variant="success" size="sm" role="status" />} Save</Button>{' '}
                                <Button variant="contained" color="secondary" onClick={() => this.props.history.push('/')} style={{ marginRight: "20px", outline: "0", pointerEvents: "all", cursor: (this.isMissingGoogleSetup()) ? "not-allowed" : "pointer" }} disabled={this.isMissingGoogleSetup()} title={this.isMissingGoogleSetup() ? `You need to click on the ${findYourBusinessOnGoogleLabel} button to set up your reviews business name.` : '' }>Close</Button>
                                <QuickLinks pageNames={["Message", "Account"]} linkLabels={["Request a review", "View account details"]} />
                            </Form>
                        )

                        }
                    </Formik>) :
                    <LoadingBackdrop open={true} />
                }
            </React.Fragment>
        );
    }

    private ensureDataFetched() {
        this.props.requestMessageSettings(this.props.messageSettingsEntity.dateUpdated);
    }
};

export default connect(
    (state: ApplicationState) => {
        return { ...state.messageSettings, ...state.account }
    },
    MessageSettingsStore.actionCreators
)(GoogleReviewTemplateCurrent as any);
