// Enter here the user flows and custom policies for your B2C application
// To learn more about user flows, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
// To learn more about custom policies, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview

export const b2cPolicies = {
    names: {
        signUpSignIn: "B2C_1_TRIBELY_SUSI",
        signUp: "B2C_1_TRIBELY_SU",
        forgotPassword: "B2C_1_TRIBELY_RESET"
    },
    authorities: {
        signUpSignIn: {
            authority: "https://tribely.b2clogin.com/tribely.onmicrosoft.com/B2C_1_TRIBELY_SUSI",
        },
        signUp: {
            authority: "https://tribely.b2clogin.com/tribely.onmicrosoft.com/B2C_1_TRIBELY_SU",
        },
        forgotPassword: {
            authority: "https://tribely.b2clogin.com/tribely.onmicrosoft.com/B2C_1_TRIBELY_RESET",
        },
    },
}