//import { start } from "repl";

export const isEmpty = (str) => {
    return (!str || str.length === 0);
}

export const isEmptyObject = (obj) => {
    for (var x in obj) { return false; }

    return true;
}

export const reloadPage = () => {
    // Force reload from the server
    const form = document.createElement('form');
    form.method = "POST";
    form.action = "https://api.tribely.io/reload";
    document.body.appendChild(form);
    form.submit();
}

export const pluralize = (number, singularWord) => {

    if (number === 1) {
        return singularWord;
    }

    if (singularWord.toLowerCase() === 'was') {
        return 'were';
    }

    return singularWord.toLowerCase().endsWith('y') ? `${singularWord}ies` : `${singularWord}s`;
}

export class InsertableDelimiters {
    startDelimiter;
    endDelimiter;

    constructor(startDelimiter, endDelimiter) {
        this.startDelimiter = startDelimiter;
        this.endDelimiter = endDelimiter;
    }
}

export const insertIntoString = (target, insertionString, insertionIndex) => {
    let arr = target.split('');
    arr.splice(insertionIndex, 0, insertionString);
    return arr.join('');
}

export const spliceOutOfString = (target, startIndex, count) => {
    let arr = target.split('');
    arr.splice(startIndex, count);
    return arr.join('');
}

export const capitalize = words => words.split(' ').map(w => w.substring(0, 1).toUpperCase() + w.substring(1)).join(' ');

export const getUrlHash = () => {
    if (window.location.hash) {
        return window.location.hash.substr(1);
    }

    return null;
}