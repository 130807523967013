import * as Msal from 'msal';
import apiConfig from './apiConfig';
import { msalConfig, msalConfigSignUpOnly, tokenRequest, loginRequest } from './authConfig';
import { b2cPolicies } from './policies'
import { callApiWithAccessToken } from './api';
import { getUrlHash } from '../utils';

// Create the main myMSALObj instance
// configuration parameters are located at authConfig.js
let authPolicy = msalConfig;
if (getUrlHash() === 'signup') {
    authPolicy = msalConfigSignUpOnly;
}

const msalUserAgent = new Msal.UserAgentApplication(authPolicy);


export const getUserAccount = () => msalUserAgent.getAccount();

let accessToken;
let launchApp;

// Register Callbacks for Redirect flow
export const initializeAuth = () => {
    msalUserAgent.handleRedirectCallback(authRedirectCallBack);
}

const authRedirectCallBack = (error, response) => {
    // Error handling
    if (error) {
        console.log(error);

        // Check for forgot password error
        // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        if (error.errorMessage.indexOf("AADB2C90118") > -1) {
            try {
                // Password reset policy/authority
                msalUserAgent.loginRedirect(b2cPolicies.authorities.forgotPassword);
            } catch (err) {
                console.log(err);
            }
        }
    } else {
        // We need to reject id tokens that were not issued with the default sign-in policy.
        // "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr")
        // To learn more about b2c tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
        if (response.tokenType === "id_token" && response.idToken.claims['acr'] !== b2cPolicies.names.signUpSignIn) {
            msalUserAgent.logout();
            window.alert("Password has been reset successfully. \nPlease sign-in with your new password.");
        } else if (response.tokenType === "id_token" && response.idToken.claims['acr'] === b2cPolicies.names.signUpSignIn) {
            console.log("id_token acquired at: " + new Date().toString());

            if (msalUserAgent.getAccount()) {
                launchApp();
            }

        } else if (response.tokenType === "access_token") {
            console.log("access_token acquired at: " + new Date().toString());
            accessToken = response.accessToken;
            if (accessToken) {
                try {
                    callApiWithAccessToken(apiConfig.webApi, accessToken);
                } catch (err) {
                    console.log(err);
                }
            }
        } else {
            console.log("Token type is: " + response.tokenType);
        }
    }
}

export const signIn = (launchApp) => {
    launchApp = launchApp;
    msalUserAgent.loginRedirect(loginRequest);
}

// sign-out the user
export const logout = () => {
    // Removes all sessions, need to call AAD endpoint to do full logout
    msalUserAgent.logout();
}

// main method to get token with redirect flow
export const getTokenRedirect = (request) => {
    return msalUserAgent.acquireTokenSilent(request)
        .then((response) => {
            if (response.accessToken) {
                accessToken = response.accessToken;

                if (accessToken) {
                    try {
                        callApiWithAccessToken(apiConfig.webApi, accessToken);
                    } catch (err) {
                        console.log(err);
                    }
                }
            }
        }).catch(error => {
            console.log("Silent token acquisition fails. Acquiring token using redirect");
            console.log(error);
            // fallback to interaction when silent call fails
            return msalUserAgent.acquireTokenRedirect(request);
        });
}


// calls the resource API with the token
export const passTokenToApi = () => {
    if (!accessToken) {
        getTokenRedirect(tokenRequest);
    } else {
        try {
            callApiWithAccessToken(apiConfig.webApi, accessToken);
        } catch (err) {
            console.log(err);
        }
    }
}
