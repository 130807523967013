import { CLIENT_COMMAND_LOCAL_STORAGE_KEY } from '../configs';

export const handleErrors = (response) => {
    if (!response.ok) {
        throw response;
    }

    return response;
}

export const getLastClientCommandMessageId = ():string | null => {
    return window.localStorage.getItem(CLIENT_COMMAND_LOCAL_STORAGE_KEY);
}

export const saveLastClientCommandMessageId = (messageId: string) => {
    window.localStorage.setItem(CLIENT_COMMAND_LOCAL_STORAGE_KEY, messageId);
}

export const applyDepcrecatedGoogleReviewsTemplate = (dateAccountCreated: string): boolean => {

    var isOldAccount = new Date(dateAccountCreated) <= new Date('2020-10-10T00:00:00.0000000Z');

    return (isOldAccount);
}

