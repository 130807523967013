import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';
import { ApplicationState } from '../store';
import * as MessagesHistoryStore from '../store/messages.store';
import "./MessagesHistory.css";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from "@material-ui/core/IconButton";
import Pagination from "@material-ui/lab/Pagination";
import Grid from "@material-ui/core/Grid";
import LoadingBackdrop from "./LoadingBackdrop";
import { pluralize } from '../utils';
import PageHeading from "./PageHeading";
import QuickLinks from './QuickLinks';
import { pageIcons } from "../configs";

type MessagesHistoryProps = MessagesHistoryStore.MessagesState &
    typeof MessagesHistoryStore.actionCreators &
    RouteComponentProps<{ startIndex: string, cmd: string }>;



class MessagesHistory extends React.PureComponent<MessagesHistoryProps> {
    readonly _maxPageCount: number = 8;

    public componentDidMount() {
        this.ensureDataFetched();
    }

    public componentDidUpdate() {
        this.ensureDataFetched();
    }

    public static pageName: string = "MessagesHistory";

    private ensureDataFetched() {
        const cmd = this.props.match.params.cmd;

        // Remove the force-refresh param and reset the URL so it doesn't keep refreshing in an endless loop
        if (cmd === 'f') {
            this.props.history.push('/messages-history/0');
        }

        const startIndex = parseInt(this.props.match.params.startIndex, 10) || 0;
        this.props.requestMessages(startIndex, this._maxPageCount, cmd);
    }

    public render() {
        const totalMessagesCount: number = this.props.totalMessagesCount;
        const enablePagination: boolean = totalMessagesCount > this._maxPageCount;

        return (
            <React.Fragment>
                <PageHeading title="Message History" titleIcon={pageIcons[MessagesHistory.pageName]} subtitle={pluralize(this.props.totalMessagesCount, "message") + " sent"} badgeText={this.props.totalMessagesCount.toString()} />
                {this.props.isLoading && <LoadingBackdrop open={true} />}
                
                <Grid container spacing={0}>
                    <Grid item xs={6} style={{display: 'flex', alignItems: "center"}}>
                        {enablePagination && this.props.messageEntities && this.renderMessagesHistoryPagination(totalMessagesCount, this.props.history)}
                    </Grid>
                    <Grid item xs={6}>
                        <IconButton style={{float: "right"}} aria-label="Refresh" component={Link} to="/messages-history/0/f">
                            <RefreshIcon style={{transition: "0.5s"}} fontSize="large" />
                        </IconButton>
                    </Grid>
                </Grid>
                {this.props.messageEntities?.length > 0 ? this.renderMessagesHistoryList() : <div>There are no messages to show</div>}
                <br />
                <QuickLinks pageNames={["Message"]} linkLabels={["Request a review"]} />
            </React.Fragment>
        );
    }

    private renderMessagesHistoryList() {
        let counter = this.props.startIndex || 0;
        counter = (counter < 0 ? 0 : counter);

        return (
            <React.Fragment>
                <TableContainer component={Paper}>
                    <Table aria-label="Messsage History">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">#</TableCell>
                                <TableCell align="center">Date Sent</TableCell>
                                <TableCell align="center">Mobile Phone</TableCell>
                                <TableCell align="center">First Name</TableCell>
                                <TableCell align="center">Last Name</TableCell>
                                <TableCell align="center">Message</TableCell>
                                <TableCell align="center">Sent by</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.messageEntities.map((message: MessagesHistoryStore.Message) => {
                                counter++;
                                return (<TableRow className="dimOnHoverTableRow" key={counter}>
                                    <TableCell align="center" scope="row">{counter}</TableCell>
                                    <TableCell align="center"><Moment local format="YYYY/MM/DD h:mm A">{message.dateCreated}</Moment></TableCell>
                                    <TableCell align="center"><NumberFormat value={message.contact.mobilePhone} format="(###) ###-####" displayType={'text'} /></TableCell>
                                    <TableCell align="center">{message.contact.firstName}</TableCell>
                                    <TableCell align="center">{message.contact.lastName}</TableCell>
                                    <TableCell align="center" style={{ fontSize: "smaller" }} title={message.message.body}>{message.message.body}</TableCell>
                                    <TableCell align="center">{message.userId}</TableCell>
                                </TableRow>);
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </React.Fragment>
        );
    }

    private renderMessagesHistoryPagination(totalCount: number, history) {

        const pagesCount: number = Math.floor(totalCount / this._maxPageCount);

        const currentPageNumber: number = Math.floor((this.props.startIndex || 0) / this._maxPageCount) + 1;

        // Build the page numbers bar
        //let pages: Array<Object> = [];
        //for (let i = 0, j = totalCount; j >= 0; j = j - this._maxPageCount, i++) {
        //    const pageSize: number = i * this._maxPageCount;
        //    const pageIndex: number = pageSize > totalCount ? ((--i) * (this._maxPageCount)) + remainder : pageSize;
        //    pages.push(<PaginationItem key={i}><PaginationLink tag={Link} to={`/messages-history/${pageIndex}`}>{i + 1}</PaginationLink></PaginationItem>);
        //}

        return (<Pagination count={pagesCount + 1} page={currentPageNumber} siblingCount={2} boundaryCount={2} onChange={(event, newPage) => history.push("/messages-history/" + ((newPage * this._maxPageCount) - this._maxPageCount))} />);
    }

}

export default connect(
    (state: ApplicationState) => state.messages,
    MessagesHistoryStore.actionCreators
)(MessagesHistory as any)