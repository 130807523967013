import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { handleErrors } from './common-impl';
import { StateBase, ApiSubmitTypes } from './common-types';
import { QUERY_API_KEY, WRITE_API_KEY, BASE_API_URI, ERROR_GENERIC_MESSAGE, GOOGLE_PLACEID_API_URI, GOOGLE_PLACEID_API_KEY } from '../configs';
import * as auth from '../authProvider';
import { TargetPlatformId } from './message.store';
import { isEmpty, isEmptyObject } from '../utils';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

enum MessageSettingsActionTypes {
    REQUEST_STARTED = "MESSAGESETTINGS_REQUEST_STARTED",
    REQUEST_SUCCEEDED = "MESSAGESETTINGS_REQUEST_SUCCEEDED",
    REQUEST_FAILED = "MESSAGESETTINGS_REQUEST_FAILED",
    REQUEST_GOOGLE_PLACEID_STARTED = "MESSAGESETTINGS_REQUEST_GOOGLE_PLACEID_STARTED",
    REQUEST_GOOGLE_PLACEID_SUCCEEDED = "MESSAGESETTINGS_REQUEST_GOOGLE_PLACEID_SUCCEEDED",
    REQUEST_GOOGLE_PLACEID_FAILED = "MESSAGESETTINGS_REQUEST_GOOGLE_PLACEID_FAILED",
    SUBMIT_STARTED = "MESSAGESETTINGS_SUBMIT_STARTED",
    SUBMIT_SUCCEEDED = "MESSAGESETTINGS_SUBMIT_SUCCEEDED",
    SUBMIT_FAILED = "MESSAGESETTINGS_SUBMIT_FAILED"
}

export interface MessageSettingsState extends StateBase {
    messageSettingsEntity: MessageSettings;
}

export interface MessageSettings {
    id: string | null,
    targetPlatforms: TargetPlatformSetting[],
    tenantId: string,
    type: string,
    userId: string,
    dateUpdated: string
}

export interface TargetPlatformSetting {
    id: string,
    uri: string,
    shortName: string,
    googleBusinessName: string,
    googlePlaceId: string,
    googlePlaceIdAddress: string,
    messageBody: string,
    messageFooter: string,
    messageFooterLink: string
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

// Fetch actions 
interface MessageSettingsRequestStartedAction {
    type: MessageSettingsActionTypes.REQUEST_STARTED;
}

interface MessageSettingsRequestSucceededAction {
    type: MessageSettingsActionTypes.REQUEST_SUCCEEDED;
    settings: MessageSettings;
}

interface MessageSettingsRequestFailedAction {
    type: MessageSettingsActionTypes.REQUEST_FAILED;
    error: any;
}


// Submit actions
interface MessageSettingsSubmitStartedAction {
    type: MessageSettingsActionTypes.SUBMIT_STARTED;
}

interface MessageSettingsSubmitSucceededAction {
    type: MessageSettingsActionTypes.SUBMIT_SUCCEEDED;
    settings: MessageSettings;
}

interface MessageSettingsSubmitFailedAction {
    type: MessageSettingsActionTypes.SUBMIT_FAILED;
    error: any;
}


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = MessageSettingsRequestStartedAction |
    MessageSettingsRequestSucceededAction |
    MessageSettingsRequestFailedAction |
    MessageSettingsSubmitStartedAction | 
    MessageSettingsSubmitSucceededAction |
    MessageSettingsSubmitFailedAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {

    requestMessageSettings: (entryDateUpdated: string | undefined): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const appState = getState();
        if (appState && appState.messageSettings &&
            (!entryDateUpdated ||
                appState.messageSettings.messageSettingsEntity.dateUpdated !== entryDateUpdated)) {
            const tenantId: string = auth.getTenantId();
            dispatch({ type: MessageSettingsActionTypes.REQUEST_STARTED });

            fetch(`${BASE_API_URI}/settings/${tenantId}`,
                {
                    'headers': { 'x-api-key': QUERY_API_KEY },
                    'method': 'GET'
                })
                .then(handleErrors)
                .then(response => response.json() as Promise<MessageSettings>)
                .then(data => {
                    if (isEmptyObject(data)) {
                        data = BuildMessageSettings(tenantId, 'GMB');
                    }
                    dispatch({ type: MessageSettingsActionTypes.REQUEST_SUCCEEDED, settings: data });
                })
                .catch(error => {

                    if (error.text) {
                        error.text().then(errorText =>
                            dispatch({
                                type: MessageSettingsActionTypes.REQUEST_FAILED, error: errorText
                            }));
                    } else {
                        dispatch({
                            type: MessageSettingsActionTypes.REQUEST_FAILED, error: ERROR_GENERIC_MESSAGE
                        });
                    }
                });
        }
    },
    submitMessageSettings: (values: TargetPlatformSetting): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const appState = getState();

        if (appState && appState.messageSettings) {
            const messageSettings = { ...appState.messageSettings.messageSettingsEntity };
            messageSettings.userId = auth.getEmail();
            messageSettings.tenantId = auth.getTenantId();
            messageSettings.type = ApiSubmitTypes.SETTINGS;

            const targetPlatformIdx = (<any>messageSettings.targetPlatforms).findIndex((item) => item.id === values.id);

            messageSettings.targetPlatforms[targetPlatformIdx] = values;

            // Always update the google uri unless it's the deprecated g.page, then keep it for old customers of GMB with shorname
            messageSettings.targetPlatforms[targetPlatformIdx].uri = isEmpty(values.uri) || !values.uri.startsWith('https://g.page') ? `https://search.google.com/local/writereview?placeid=${values.googlePlaceId}` : values.uri;
            
            dispatch({ type: MessageSettingsActionTypes.SUBMIT_STARTED });

            fetch(`${BASE_API_URI}/settings`,
                {
                    'headers': {
                        'x-api-key': WRITE_API_KEY,
                        'Content-Type': 'application/json'},
                    'method': 'POST',
                    'body': JSON.stringify(messageSettings)
                })
                .then(handleErrors)
                .then(response => response.json() as Promise<MessageSettings>)
                .then(responseData => {
                    dispatch({ type: MessageSettingsActionTypes.SUBMIT_SUCCEEDED, settings: responseData });
                })
                .catch(error => {

                    if (error.text) {
                        error.text().then(errorText =>
                            dispatch({
                                type: MessageSettingsActionTypes.SUBMIT_FAILED, error: errorText
                            }));
                    } else {
                        dispatch({
                            type: MessageSettingsActionTypes.SUBMIT_FAILED, error: ERROR_GENERIC_MESSAGE
                        });
                    }
                });
        }
    }

};

const BuildMessageSettings = (tenantId: string, targetPlatformId: TargetPlatformId): MessageSettings => {
    const targetPlatforms: Array<TargetPlatformSetting> = [
        {
            id: targetPlatformId,
            uri: '',
            shortName: '',
            googleBusinessName: '',
            googlePlaceId: '',
            googlePlaceIdAddress: '',
            messageBody: '',
            messageFooter: '',
            messageFooterLink: ''
        }
    ];

    return {
        id: null,
        targetPlatforms: targetPlatforms,
        tenantId: tenantId,
        type: ApiSubmitTypes.SETTINGS,
        userId: '',
        dateUpdated: ''
    };
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: MessageSettingsState = {
    isLoading: false,
    messageSettingsEntity: <MessageSettings>{},
    error: null
};

export const reducer: Reducer<MessageSettingsState> = (state: MessageSettingsState | undefined, incomingAction: Action): MessageSettingsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case MessageSettingsActionTypes.REQUEST_STARTED:
            return {
                isLoading: true,
                messageSettingsEntity: state.messageSettingsEntity,
                error: null
            };
        case MessageSettingsActionTypes.REQUEST_SUCCEEDED:
            return {
                isLoading: false,
                messageSettingsEntity: action.settings,
                error: null
            };
        case MessageSettingsActionTypes.REQUEST_FAILED:
            return {
                isLoading: false,
                messageSettingsEntity: state.messageSettingsEntity,
                error: action.error
            };
        case MessageSettingsActionTypes.SUBMIT_STARTED:
            return {
                isLoading: true,
                messageSettingsEntity: state.messageSettingsEntity,
                error: null
            };
        case MessageSettingsActionTypes.SUBMIT_SUCCEEDED:
            return {
                isLoading: false,
                messageSettingsEntity: action.settings,
                error: null
            };
        case MessageSettingsActionTypes.SUBMIT_FAILED:
            return {
                isLoading: false,
                messageSettingsEntity: state.messageSettingsEntity,
                error: action.error
            };
        default:
            return state;
    }
};
